<template>
  <v-card elevation="1" class="p-3 w-100 h-100" flat tile>
    <v-card-actions>
      <h4 class="pl-4 subtitle-2">{{ label }}</h4>
      <v-spacer />
      <v-btn icon @click="showMore = !showMore">
        <v-icon>{{ showMore ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider />
    <div v-show="showMore" class="grey lighten-4">
      <v-range-slider
        class="mx-5 pt-3"
        :max="range.max"
        :min="range.min"
        :value="value"
        validate-on-blur
        @end="handleFilter"
      >
        <template #prepend>
          <div class="flex justify-center align-items mt-1">
            {{ range.min }}
          </div>
        </template>

        <template #thumb-label="thumb">
          {{ thumb.value }}
        </template>
        <template #append>
          <div class="flex justify-center align-items mt-1">
            {{ range.max }}
          </div>
        </template>
      </v-range-slider>
    </div>
  </v-card>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue';

const props = defineProps({
  show: { type: Boolean, default: false },
  label: { type: String, default: 'Date' },
  field: { type: String, default: 'radioBoxes' },
  value: { type: Array, default: () => [0, 0] },
  range: { type: Object, default: () => ({ min: 0, max: 100, initial: 0 }) }
});

const showMore = ref(props.show);

const emit = defineEmits(['input', 'clearFacet', 'removeFilter']);

const handleFilter = value => {
  if (value !== null) emit('input', value);
  else emit('clearFacet');
};
</script>
