/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { format, addDays } from 'date-fns';
import store from '@/store';

const getLabel = require('@/utils/labels.js').default;
const brandsConfig = require('./brands.json');

const { VUE_APP_ENVIROMENT } = process.env;

const findLabel = label => {
  if (!label) {
    return null;
  }
  return getLabel(label);
};

const getFacetsConfiguration = view => {
  const brandConfig = brandsConfig[store.getters.getActiveBrand][VUE_APP_ENVIROMENT];

  const allowedConfig = {};
  //  GET CONFIG FILE
  if (brandConfig[view].facetConfigFile) {
    const facetConfigFile = require(`./${brandConfig[view].facetConfigFile}`);

    Object.entries(facetConfigFile).forEach(([key, property]) => {
      if (store.state.user?.roles && property.roles) {
        const isAllowed = store.getters.allowAction(property.roles);

        if (!isAllowed) return;
      }
      if (property.hideFieldWhen) {
        const isAllowed = store.getters.allowField(property.hideFieldWhen);

        if (!isAllowed) return;
      }

      allowedConfig[key] = property;
    });
  }

  return allowedConfig;
};

const getTargetingConfig = context => {
  if (!context) {
    return null;
  }

  const facetConfigFile = require(`./${context}TargetingFacets.json`);
  return facetConfigFile;
};

const getDataTableHeadersConfiguration = view => {
  const brandConfig = brandsConfig[store.getters.getActiveBrand][VUE_APP_ENVIROMENT];

  //  GET CONFIG FILE
  const allowedConfig = [];
  if (brandConfig[view].tableConfig) {
    const configHeader = require(`./${brandConfig[view].tableConfig}`);

    configHeader.forEach(property => {
      if (property.roles) {
        const isAllowed = store.getters.allowAction(property.roles);

        if (!isAllowed) return;
      }
      if (property.hideFieldWhen) {
        const isAllowed = store.getters.allowField(property.hideFieldWhen);

        if (!isAllowed) return;
      }

      if (property.label) {
        property.text = findLabel(property.label);
      }

      allowedConfig.push(property);
    });
  }

  return allowedConfig;
};

const formatDate = (date, formatStr = 'dd-MM-yyyy') => format(date, formatStr);

const addDayToDate = dateStr => format(addDays(new Date(dateStr), 1), 'yyyy-MM-dd');

const formatDateRange = dates => {
  if (!dates || dates.length !== 2) return 'Choisir Dates';

  if (dates && dates[0] && !dates[1]) return 'Choisir la date de fin';

  if (dates && !dates[0] && dates[1]) return 'Choisir la date de début';

  const [startDate, endDate] = dates;
  const startYear = formatDate(startDate, 'yyyy');
  const endYear = formatDate(endDate, 'yyyy');

  if (startYear === endYear) {
    return `${formatDate(startDate, 'MMM d')} - ${formatDate(endDate, 'MMM d, yyyy')}`;
  }

  return `${formatDate(startDate, 'MMM d, yyyy')} - ${formatDate(endDate, 'MMM d, yyyy')}`;
};

export { getFacetsConfiguration, getDataTableHeadersConfiguration, getTargetingConfig, formatDate, formatDateRange, addDayToDate };
