<template>
  <v-card elevation="1" class="col-12 pa-0" flat tile>
    <v-card-actions>
      <h4 class="pl-4 subtitle-2">{{ label }}</h4>
      <v-spacer />
      <v-btn icon @click="showMore = !showMore">
        <v-icon>{{ showMore ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider />
    <v-expand-transition>
      <div v-show="showMore" class="grey lighten-4">
        <v-card-text>
          <v-text-field
            v-if="searchable"
            class="mb-2 white"
            hide-details
            append-icon="mdi-magnify"
            outlined
            dense
            label="rechercher"
            type="text"
          />
          <div
            v-for="item in itemsToShow"
            :key="item.value"
          >
            <label class="d-flex align-center">
              <input v-model="selectedValue" class="mb-1" type="radio" :value="item.value" :name="field">
              <span v-if="item.label" class="mx-1">{{ item.label }}</span>
              <span v-else-if="item.value" class="mx-1">{{ item.value }}</span>
              <span v-if="item.count" class="caption ml-auto">({{ item.count }})</span>
            </label>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
import { computed, ref, defineEmits, defineProps } from 'vue';

const props = defineProps({
  label: { type: String, default: 'Date' },
  field: { type: String, default: 'radioBoxes' },
  show: { type: Boolean, default: false },
  value: { type: [Boolean, String], default: null },
  items: { type: Array, default: () => [] },
  searchable: { type: Boolean, default: false }
});

const emit = defineEmits(['input', 'clearFacet', 'removeFilter']);

const showMore = ref(props.show);
const query = ref('');

const selectedValue = computed({
  get: () => props.value,
  set: v => {
    if (v !== null) emit('input', v);
    else emit('clearFacet');
  }
});

const searchItems = () => props.items.filter(i => i.value.toLowerCase().includes(query.value.toLowerCase()));

const itemsToShow = computed(() => (query.value.trim() !== '' ? searchItems() : props.items));

</script>
