<template>
  <div v-if="facetsToShow.length" class="breadcrumbs d-flex flex-wrap">
    <v-chip
      v-for="(facet, i) in facetsToShow"
      :key="i"
      ref="chips"
      label
      small
      class="ma-1 red white--text"
      dark
      close
      @click:close="removeFacet(facet.field, facet.value)"
    >
      <div>
        <span>{{ facet.label }}</span>
      </div>
    </v-chip>
    <v-btn x-small text class="d-flex ma-1 align-center" @click="emit('clearFilters')">
      <v-icon class="mr-1" small> {{ "mdi-close-circle" }}</v-icon>
      <span>effacer tout</span>
    </v-btn>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, watch, nextTick } from 'vue';
import { formatDate, formatDateRange } from '../configs/helper';

const props = defineProps({ facets: { type: Array, default: () => [] }, filters: { type: Array, default: () => ([]) } });
const chips = ref([]);

const emit = defineEmits(['removeFilter', 'clearFilters']);

const valueText = (value, facet) => {
  if (facet.component === 'date') return formatDate(value);
  if (facet.component === 'dateRange') return formatDateRange(value);

  if (facet.items) {
    const item = facet.items.find(i => i.value === value);
    if (item && item.label) return item.label;
  }

  return value;
};

const getBreadcrumbLabel = (value, facet) => {
  let label = '';

  if (facet.breadcrumbTitle) label += `${facet.breadcrumbTitle}: `;

  if (facet.type === 'multiField') {
    label += facet.items.find(item => item?.value === value).label || '';
  } else if (facet.range) {
    label += `${valueText(value[0], facet)} - ${valueText(value[1], facet)}`;
  } else label += valueText(value, facet);

  return label;
};

const facetsToShow = computed(() => {
  const toShow = [];
  props.facets.forEach(facet => {
    const filter = props.filters.find(f => f.field === facet.field);
    if (filter) {
      if (facet.type === 'multiValue' || facet.type === 'multiField') {
        filter.value.forEach(value => toShow.push({ value, field: filter.field, label: getBreadcrumbLabel(value, facet) }));
      } else {
        toShow.push({ value: filter.value, field: filter.field, label: getBreadcrumbLabel(filter.value, facet) });
      }
    }
  });
  return toShow;
});

watch(facetsToShow, async newValue => {
  if (newValue.length) {
    await nextTick();
    const lastChip = chips.value[newValue.length - 1];
    lastChip.$el.scrollIntoView();
  }
});

const removeFacet = (field, value) => {
  emit('removeFilter', field, value);
};

</script>
<style lang="scss">
.breadcrumbs {
  min-height: 40px;
}
.clear-facets {
  text-decoration: underline;
  cursor: pointer;
}
.inline {
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
</style>
