<template>
  <v-card elevation="1" class="col-12 pa-0" flat tile>
    <v-card-actions>
      <h4 class="pl-4 subtitle-2">{{ label }}</h4>
      <v-spacer />
      <v-btn v-if="items && items.length" icon @click="showMore = !showMore">
        <v-icon>{{ showMore ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider />
    <v-expand-transition v-if="items && items.length">
      <div v-show="showMore" class="grey lighten-4">
        <v-card-text class="d-flex flex-column">
          <v-text-field
            v-if="searchable"
            v-model="query"
            class="mb-2 white"
            hide-details
            append-icon="mdi-magnify"
            outlined
            dense
            :label="label"
            type="text"
          />
          <div v-if="itemsToShow.length" :style="maxHeight ? 'overflow-y: auto; max-height: ' + maxHeight : ''">
            <div
              v-for="item in itemsToShow"
              :key="item.value"
              class="facet-item"
              :class="isChecked(item.value) ? 'facet-item--selected' : ''"
              style="padding: 0.1rem 0.5rem;"
            >
              <label class="d-flex align-center" :title="item.value">
                <input v-model="checkedValues" class="mb-1" :name="field" type="checkbox" :value="item.value">
                <span v-if="item.label" class="mx-1 text-truncate">{{ item.label }}</span>
                <span v-else-if="item.value" class="mx-1 text-truncate">{{ item.value }}</span>
                <span v-if="item.count" class="caption counter ml-auto">{{ item.count }}</span>
              </label>
            </div>
          </div>
          <div v-else class="caption grey--text text--darken-2">
            Aucun résultat trouvé ...
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
import { computed, ref, defineEmits, defineProps } from 'vue';

const props = defineProps({
  label: { type: String, default: 'Date' },
  field: { type: String, default: 'checkBoxes' },
  show: { type: Boolean, default: false },
  value: { type: Array, default: () => [] },
  items: { type: Array, default: () => [] },
  searchable: { type: Boolean, default: false },
  maxHeight: { type: String, default: null }
});

const emit = defineEmits(['input', 'clearFacet', 'removeFilter']);

const showMore = ref(props.show);
const query = ref('');

const checkedValues = computed({
  get: () => props.value,
  set: v => {
    if (v.length) {
      emit('input', v);
    } else emit('clearFacet');
  }
});

const isChecked = value => props.value && props.value.some(v => v === value);

const searchItems = () => props.items.filter(i => i.value.toLowerCase().includes(query.value.toLowerCase()));

const itemsToShow = computed(() => (query.value.trim() !== '' ? searchItems() : props.items).slice(0, 5));
</script>

<style lang="scss" scoped>
.counter {
  padding: .1rem .4rem;
    font-size: .8rem;
    color: #703a3a;
    background-color: #eedfdf;
    border-radius: 8px;
}
</style>
