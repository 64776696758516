<template>
  <v-card class="col-12 pa-0" flat tile>
    <v-card-actions>
      <h4 class="pl-4 subtitle-2">{{ label }}</h4>
      <v-spacer />
      <v-btn icon @click="showMore = !showMore">
        <v-icon>{{ showMore ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider />
    <v-expand-transition>
      <div v-show="showMore" class="grey lighten-4">
        <v-row v-if="!props.expanded" class="no-gutters">
          <v-col class="d-flex justify-center py-0 my-2" cols="12">
            <v-menu
              offset-y
              :nudge-left="75"
              :close-on-content-click="false"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  color="black"
                  class="text-body-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ formatedDate }}
                </v-btn>
              </template>
              <div>
                <v-date-picker
                  :value="value"
                  color="red"
                  :show-current="showCurrent"
                  no-title
                  locale="fr"
                  @input="handleFilter"
                />
              </div>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-else class="no-gutters">
          <v-col class="d-flex justify-center py-0 mb-2" cols="12">
            <v-date-picker
              :value="value"
              full-width
              color="red"
              :show-current="showCurrent"
              no-title
              locale="fr"
              @input="handleFilter"
            />
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed } from 'vue';
import { formatDate } from '../../configs/helper';

const props = defineProps({
  label: { type: String, default: 'Date' },
  show: { type: Boolean, default: false },
  value: { type: String, default: null },
  showCurrent: { type: Boolean, default: () => true },
  expanded: { type: Boolean, default: () => true }
});

const emit = defineEmits(['input', 'clearFacet']);

const showMore = ref(props.show);

const formatedDate = computed(() => (props.value ? formatDate(props.value) : 'Choisir Date'));

const handleFilter = input => {
  if (input) {
    if (input === props.value) { // Remove date filter if active
      emit('clearFacet');
    } else {
      emit('input', input);
    }
  }
};

</script>
