/* eslint-disable no-underscore-dangle */
import fetch from 'node-fetch';
import store from '@/store';

async function makeApiRequest (path, method = 'GET', body = {}, signal = null) {
  const params = {
    method: method,
    headers: {
      'content-Type': 'application/json',
      Authorization: `Bearer ${store.state.token}`
    },
    body: Object.keys(body).length !== 0 ? JSON.stringify(body) : undefined,
    signal: signal
  };
  return fetch(`${path}`, params)
    .then(response => response.json())
    .then(async response => {
      if (!response.success && response.message) {
        throw (response.message);
      }

      if (response.redirectUrl) {
        if (response.new_tab) return window.open(response.redirectUrl, '_blank');
        window.location.href = response.redirectUrl;
      }

      return response;
    }).catch(err => {
      console.log('Error', err);
      throw (err);
    });
}

// eslint-disable-next-line import/prefer-default-export
export const requests = {
  async getParticipants ({ filter = null, facets, from = 0, limit = 25, sort }, signal = null) {
    const params = [];

    if (filter) {
      params.push(`filter=${JSON.stringify(filter)}`);
    }
    if (facets) {
      params.push(`facets=${JSON.stringify(facets)}`);
    }
    if (sort) {
      params.push(`sort=${JSON.stringify(sort)}`);
    }
    params.push(`from=${JSON.stringify(from)}`);
    params.push(`limit=${JSON.stringify(limit)}`);

    return makeApiRequest(`/api/participants?${params.join('&')}`, 'GET', {}, signal);
  },
  async redirectToDashboard (url, newTab = false) {
    return makeApiRequest(`/redirect?location=${url}${newTab ? '&newtab=true' : ''}`, 'GET');
  }
};
