import VueRouter from 'vue-router';

// Used Components
import Home from '@/views/Home';
import MasterLayout from '@/views/template/MasterLayout.vue';

import { isAuthenticated } from '@/utils/auth';
import store from '@/store';

const { VUE_APP_SSO_PUBLIC_JWK_SET } = process.env;

const routes = [
  {
    path: '/',
    component: MasterLayout,
    children: [
      { path: '/', name: 'Home', component: Home }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  const userWorkspaces = await isAuthenticated(store.state.token, VUE_APP_SSO_PUBLIC_JWK_SET);
  console.log('userWorkspaces :', userWorkspaces);

  if (!userWorkspaces) {
    window.location.href = `${window.location.origin}/logout`;
    return next(false);
  }

  store.commit('setUserPlatforms', userWorkspaces);

  return next();
});

export default router;
